.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  display: none;
}

h6 {
  font-weight: 700 !important;
}

/* select-item */
.select-btn {
  width: fit-content;
  min-width: 5rem;
  max-width: 20rem;
  display: flex;
  height: 1.575rem;
  padding: 0.15rem 0.5rem;
  gap: 0.5rem;
  border-radius: 0.25rem;
  color: #495057;
  background: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  cursor: pointer;
}
.select-btn.open {
  filter: drop-shadow(0px 0px 1px #0066ff50);
  border: 1px solid #0066ff;
}

.select-btn .select-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 0.8rem;
  font-weight: 400;
  color: #333;
}
.select-btn .select-img {
  width: 0.5rem;
}

.list-items {
  width: 5rem;
  height: fit-content;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  right: calc(var(--bs-gutter-x) * 0.75);
  z-index: 1;
  margin-top: 5px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  line-height: 1.5;
  display: none;
  flex-direction: column;
  gap: 0.25rem;
  color: #495057;
  background: #fff;
  border: 1px solid #adadad;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.4));
}
.select-btn.open ~ .list-items {
  display: flex;
}

.list-items .item {
  font-size: 0.8rem;
  font-weight: 400;
  color: #333;
  text-align: center;
  list-style: none;
  cursor: pointer;
  transition: 0.3s;
  padding: 0.1rem 0;
  border-radius: 0.5rem;
}
.list-items .item:hover {
  background-color: #d3d3d35b;
  color: #2a7fff;
}
.item.checked {
  background-color: #2a7fff;
  color: #fff;
}

/* table */
.table {
  border-collapse: collapse;
  cursor: default;
  margin: 0 !important;
}
.table-wrap {
  width: 100%;
  overflow-x: scroll;
}
.th {
  font-size: 0.85rem;
  white-space: nowrap;
  padding: 0.5rem 0.5rem !important;
  background-color: #fbfbfb !important;
}
.th > div {
  justify-content: right;
}
.th.sort {
  cursor: pointer;
}
.th.sort.sorted * {
  color: #0066ff;
}
.tbody tr:hover {
  background-color: #fbfbfb;
}
.td {
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem !important;
  white-space: nowrap;
  text-align: right;
}
.td:first-child {
  white-space: normal;
  width: 70px;
}
.td:nth-child(2) {
  white-space: normal;
  width: 170px;
}
.table-btn {
  width: 11px;
  text-align: right;
  border: none;
  background: transparent;
  padding: 0;
  padding-left: 0.25rem;
}
.table-card {
  padding: 0 0.25rem !important;
}

/* pagination */

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem !important;
  gap: 0.5rem;
  font-size: 0.6rem !important;
  white-space: nowrap;
  width: 100%;
  overflow-x: scroll;
}

@media only screen and (max-width: 650px) {
  .pagination {
    justify-content: flex-start;
  }
}

.pagination-btn {
  font-weight: 600;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  color: #333;
  height: 1.575rem;
  min-width: 1.575rem;
  text-align: center;
}
.pagination-btn.current {
  border: 1px solid #fff;
  color: #fff;
  background-color: #0066ff;
}
.pagination-btn.change {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  color: #0066ff;
  background-color: #fff;
}
.pagination-btn.change:disabled {
  color: #cccccc;
}
.pagination-btn:not(:disabled, .change):hover {
  background-color: #f0f0f0;
  color: #333;
}

.select {
  width: fit-content;
  min-width: 5rem;
  max-width: 20rem;
  display: flex;
  height: 1.575rem;
  padding: 0.15rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e1e5eb;
  outline: 0;
  cursor: pointer;
  font-size: 0.5rem;
}

.chart-wrap {
  height: 100%;
}
.chart-wrap .card {
  height: 100% !important;
}

.btn {
  font-size: 0.5rem !important;
  height: 1.575rem !important;
  padding: 0 0.5rem !important;
}

.btn-group {
  margin-right: 0.5rem;
}

.upload-modal {
  opacity: 0;
  position: fixed;
  display: block;
  top: 20px;
  left: calc(50% - 100px);
  width: 200px;
  /* height: 50px; */
  background: #fff;
  padding: 10px 30px;
  text-align: center;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.1);
  z-index: 999;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
}
.upload-modal.show {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.upload-text {
  font-size: 0.8rem;
}

.progress-bar {
  height: 4px !important;
  width: 100%;
  background-color: #0066ff;
  margin-top: 5px;
  border-radius: 2px;
}
.upload-modal.show .progress-bar {
  animation: progress-bar 2s forwards;
}
@keyframes progress-bar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.progress-bar.warn {
  display: none;
}

.file-form {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.file-form .btn {
  height: 1.73rem !important;
}

.login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: 300px !important;
  max-width: 85%;
  margin: 120px auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  gap: 20px;
}

.login-wrap h3 {
  font-weight: 900;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.form-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.form-item input {
  width: fit-content;
  min-width: 5rem;
  max-width: 20rem;
  display: flex;
  height: 1.575rem;
  padding: 0.15rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e1e5eb;
  outline: 0;
  cursor: pointer;
  font-size: 0.5rem;
}

.login-form .btn {
  width: 60px;
  height: 30px !important;
  font-size: 0.5rem !important;
}

.logout-btn {
  position: absolute;
  width: 60px !important;
  height: 30px !important;
  right: 1.5rem;
  top: calc(3rem);
}

.page-header {
  flex-direction: row;
}

.form-control {
  width: auto !important;
}

.timePicker {
  position: absolute;
  width: auto !important;
  height: 30px !important;
  right: 6rem;
  top: calc(3rem);
}
